import React from 'react';
import {
  Button,
  LinearGradient,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import Images from '../config/Images';
import amplitudeEvents from '../global-functions/amplitudeEvents';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const AlamoDrafthouseTechSupportScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    console.log('Screen ON_SCREEN_FOCUS Start');
    let error = null;
    try {
      if (!isFocused) {
        return;
      }
      console.log('Start ON_SCREEN_FOCUS:0 CUSTOM_FUNCTION');
      amplitudeEvents('view web app', undefined);
      console.log('Complete ON_SCREEN_FOCUS:0 CUSTOM_FUNCTION');
    } catch (err) {
      console.error(err);
      error = err.message ?? err;
    }
    console.log(
      'Screen ON_SCREEN_FOCUS Complete',
      error ? { error } : 'no error'
    );
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App['Custom #f1c232'],
          bottom: 0,
          flex: 1,
          height: '100%',
          left: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          marginTop: 0,
          right: 0,
          top: 0,
          width: '100%',
        },
        dimensions.width
      )}
    >
      <LinearGradient
        endX={100}
        endY={100}
        startX={0}
        startY={0}
        color1={palettes.App.NFT_Time_Mac_Gray}
        color2={palettes.App.NFT_TIME_Cyan_White}
        style={StyleSheet.applyWidth(
          {
            alignContent: 'stretch',
            alignSelf: 'stretch',
            flex: 1,
            height: '100%',
            width: '100%',
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: 'stretch',
              alignSelf: 'stretch',
              height: '100%',
              justifyContent: 'space-evenly',
              marginBottom: 2,
              opacity: 1,
              width: '100%',
            },
            dimensions.width
          )}
        >
          <Touchable
            onPress={() => {
              const handler = async () => {
                try {
                  await WebBrowser.openBrowserAsync(
                    'https://scienceoncall.com'
                  );
                  amplitudeEvents('click logo', undefined);
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
          >
            {/* logo */}
            <Image
              resizeMode={'contain'}
              source={imageSource(Images['adclogost2crgbtrim'])}
              style={StyleSheet.applyWidth(
                {
                  bottom: 0,
                  height: 120,
                  left: '10%',
                  opacity: 1,
                  right: '10%',
                  width: '80%',
                },
                dimensions.width
              )}
            />
            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.text.strong,
                  fontFamily: 'Poppins_600SemiBold',
                  fontSize: 14,
                  marginTop: 0,
                  paddingBottom: 4,
                  paddingTop: 0,
                  textAlign: 'center',
                },
                dimensions.width
              )}
            >
              {'tech support'}
            </Text>
          </Touchable>
          {/* text */}
          <Button
            iconPosition={'left'}
            onPress={() => {
              try {
                Linking.openURL('sms:+13125210016');
                amplitudeEvents('initiate text', undefined);
              } catch (err) {
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ButtonStyles(theme)['Button'].style,
              dimensions.width
            )}
            title={'🔥 Text 🔥'}
          >
            {'Sign Up'}
          </Button>
          {/* form */}
          <Button
            iconPosition={'left'}
            onPress={() => {
              console.log('form ON_PRESS Start');
              let error = null;
              try {
                console.log('Start ON_PRESS:1 LINKING_OPENURL');
                Linking.openURL(
                  'https://support.scienceoncall.com/hc/en-us/requests/new?ticket_form_id=360003461192'
                );
                console.log('Complete ON_PRESS:1 LINKING_OPENURL');
                console.log('Start ON_PRESS:2 CUSTOM_FUNCTION');
                amplitudeEvents('initiate form', undefined);
                console.log('Complete ON_PRESS:2 CUSTOM_FUNCTION');
              } catch (err) {
                console.error(err);
                error = err.message ?? err;
              }
              console.log(
                'form ON_PRESS Complete',
                error ? { error } : 'no error'
              );
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ButtonStyles(theme)['Button'].style,
              dimensions.width
            )}
            title={'Form'}
          >
            {'Sign Up'}
          </Button>
          {/* email */}
          <Button
            iconPosition={'left'}
            onPress={() => {
              try {
                Linking.openURL('mailto:support@scienceoncall.com');
                amplitudeEvents('initiate email', undefined);
              } catch (err) {
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ButtonStyles(theme)['Button'].style,
              dimensions.width
            )}
            title={'Email'}
          >
            {'Sign Up'}
          </Button>
          {/* call */}
          <Button
            iconPosition={'left'}
            onPress={() => {
              try {
                Linking.openURL('tel:+13125210016');
                amplitudeEvents('initiate call', undefined);
              } catch (err) {
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                { backgroundColor: theme.colors.text.strong }
              ),
              dimensions.width
            )}
            title={'Call'}
          >
            {'Sign Up'}
          </Button>
        </View>
      </LinearGradient>
    </ScreenContainer>
  );
};

export default withTheme(AlamoDrafthouseTechSupportScreen);
