import * as StyleSheet from './utils/StyleSheet';

import Breakpoints from './utils/Breakpoints';

import palettes from './themes/palettes';

export const ButtonStyles = theme =>
  StyleSheet.create({
    Button: {
      style: {
        backgroundColor: theme.colors.text.strong,
        borderRadius: 8,
        color: palettes.Brand['Light Inverse'],
        fontFamily: 'Poppins_700Bold',
        fontSize: 48,
        marginBottom: '1%',
        marginLeft: '10%',
        marginRight: '10%',
        marginTop: '0%',
        textAlign: 'center',
        width: '80%',
      },
      props: {},
    },
    'lil button': {
      style: {
        backgroundColor: palettes.Brand['Light Inverse'],
        borderRadius: 8,
        color: theme.colors.text.strong,
        fontFamily: 'Poppins_700Bold',
        fontSize: 18,
        marginBottom: '5%',
        marginLeft: '10%',
        marginRight: '10%',
        marginTop: '2%',
        textAlign: 'center',
      },
      props: {},
    },
  });

export const WebViewStyles = theme =>
  StyleSheet.create({ 'Web View': { style: { flex: 1 }, props: {} } });

export const TextStyles = theme =>
  StyleSheet.create({
    Text: { style: { color: theme.colors.text.strong }, props: {} },
  });
